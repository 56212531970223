@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  @apply font-plex text-golden-tainoi-950;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  @apply py-2 px-4 rounded-md;
}

.btn.btn-primary {
  @apply bg-primary;
}

.btn.btn-create-bill {
  @apply outline outline-2 outline-dashed text-create-bill-button-outline rounded-full
  outline-create-bill-button-outline 
  hover:bg-create-bill-button-hover 
  active:bg-create-bill-button-active;
}

input {
  @apply focus:outline-none;
}
